import { createSlice } from '@reduxjs/toolkit';

export interface User {
    address: string;
    city: string;
    email: string;
    firstName: string;
    lastName: string;
    postcode: string;
    phone: string;
    gender: string;
}

export interface UserState {
    user: User;
}

const initialState: UserState = {
    user: {
        address: '',
        city: '',
        email: '',
        firstName: '',
        lastName: '',
        postcode: '',
        phone: '',
        gender: '',
    },
};

export const userDetailsSlice = createSlice({
    name: 'userDetailsSlice',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state.user = { ...action.payload };
        },
        resetUserDetails: () => initialState,
    },
});

export const { setUserDetails, resetUserDetails } = userDetailsSlice.actions;
export const { reducer: userDetailsReducer } = userDetailsSlice;
