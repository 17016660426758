import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOrderStatus, getStripePaymentDetail } from '../../../services/restApis';
import { LoadingState } from '../../../types';
import { SkiBag } from '../../Order/store/slice';

export const getOrderStatusAction: any = createAsyncThunk('getorderStatus', async (trackingId: string) => {
    const response = await getOrderStatus(trackingId);
    return response.data;
});

export const getStripePaymentDetailAction: any = createAsyncThunk('retrieveStripePaymentMethod', async (_, { getState }) => {
    const state: any = getState();
    const payload = { intent_id: state.orderStatus.stripePaymentIntentId };
    const response = await getStripePaymentDetail(payload);
    return response.data;
});

export interface UserDetail {
    address: string;
    city: string;
    email: string;
    first_name: string;
    last_name: string;
    postcode: string;
    phone: string;
    gender: string;
}

export interface OrderStatusList {
    id: number;
    checkmark: number;
    is_expose: boolean;
    order_stage: number;
    translations: any;
}

export interface StripePaymentDetails {
    card_last_4: string;
    expiry_month: number;
    expiry_year: number;
    type: string;
}

export interface OrderDetails {
    bag: SkiBag | null;
    userDetail: UserDetail | null;
    services: any[] | null;
    totalAmount: number | null;
    status: number | null;
    stripePaymentIntentId: string | null;
    api: { loading: LoadingState; error: null };
    stripeApi: { loading: LoadingState; error: null };
    stripePaymentDetails: StripePaymentDetails | null;
    orderStatusList: OrderStatusList[] | null;
}

const initialState: OrderDetails = {
    services: null,
    bag: null,
    stripePaymentIntentId: null,
    totalAmount: null,
    userDetail: null,
    status: null,
    api: { loading: 'idle', error: null },
    stripeApi: { loading: 'idle', error: null },
    stripePaymentDetails: null,
    orderStatusList: null,
};

export const orderStatusSlice = createSlice({
    name: 'orderDetails',
    initialState,
    reducers: {},
    extraReducers: {
        [getOrderStatusAction.pending]: state => {
            state.api.loading = 'pending';
            state.api.error = null;
        },
        [getOrderStatusAction.fulfilled]: (state, action) => {
            state.bag = action.payload.order.bag_detail;
            state.services = action.payload.order.services;
            state.userDetail = action.payload.order.user_detail;
            state.stripePaymentIntentId = action.payload.order.stripe_payment_intent_id;
            state.totalAmount = action.payload.order.total;
            state.status = action.payload.order.order_status;
            state.orderStatusList = action.payload.order_status;
            state.api.loading = 'succeeded';
        },
        [getOrderStatusAction.rejected]: (state, action) => {
            state.api.error = action.error;
            state.api.loading = 'failed';
        },
        [getStripePaymentDetailAction.pending]: state => {
            state.stripeApi.loading = 'pending';
            state.stripeApi.error = null;
        },
        [getStripePaymentDetailAction.fulfilled]: (state, action) => {
            state.stripePaymentDetails = action.payload;
            state.stripeApi.loading = 'succeeded';
        },
        [getStripePaymentDetailAction.rejected]: (state, action) => {
            state.stripeApi.error = action.error;
            state.stripeApi.loading = 'failed';
        },
    },
});

export const { reducer: orderStatusReducer } = orderStatusSlice;
