import { matchRoutes } from 'react-router-dom';

import { routes } from './routes';

const routesMatch: any = matchRoutes(routes, window.location);

export default {
    production: {
        url: `https://pickhome-backend.eu.aldryn.io/${
            routesMatch && routesMatch.length ? routesMatch[0]?.params?.locale : 'de'
        }`,
    },
    staging: {
        url: `https://pickhome-backend-stage.eu.aldryn.io/${
            routesMatch && routesMatch.length ? routesMatch[0]?.params?.locale : 'de'
        }`,
    },
    local: {
        url: `http://localhost:8000/${
            routesMatch && routesMatch.length ? routesMatch[0]?.params?.locale : 'de'
        }`,
    }
};
