import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import CONFIG from '../constants/config';

class XHR {
    public client: AxiosInstance;
    private token!: string;

    constructor(config: CreateAxiosDefaults) {
        this.setToken('Bearer token');

        this.client = axios.create(config);
    }

    public getToken(): string {
        return this.token;
    }

    public setToken(token: string): void {
        this.token = token;
    }
}

const backendXHR = new XHR({
    baseURL: CONFIG.backend.url,
    timeout: 1000000,
    withCredentials: false,
});

export { backendXHR };
