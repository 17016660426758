import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSecretKey, GetSecretKeyPayload } from '../../../services/restApis';
import { LoadingState } from '../../../types';

export const getClientSecret: any = createAsyncThunk('getClientSecret', async (param: GetSecretKeyPayload) => {
    const response = await getSecretKey(param);
    return response.data;
});

export interface PaymentState {
    stripeClientSecret: string;
    clientSecretloading: LoadingState;
    clientSecretError: null;
    stripePaymentIntentId: string | null;
    confirmPaymentOnMobile: boolean;
}

const initialState: PaymentState = {
    stripeClientSecret: '',
    clientSecretloading: 'idle',
    clientSecretError: null,
    stripePaymentIntentId: null,
    confirmPaymentOnMobile: false,
};

const paymentSlice = createSlice({
    name: 'paymentSlice',
    initialState,
    reducers: {
        setConfirmPaymentOnMobile: state => {
            state.confirmPaymentOnMobile = !state.confirmPaymentOnMobile;
        },
        resetPayment: () => initialState,
    },
    extraReducers: {
        [getClientSecret.pending]: state => {
            if (state.clientSecretloading === 'idle') {
                state.clientSecretloading = 'pending';
                state.clientSecretError = null;
            }
        },
        [getClientSecret.fulfilled]: (state, action) => {
            if (state.clientSecretloading === 'pending') {
                state.stripeClientSecret = action.payload.paymentIntent.client_secret;
                state.stripePaymentIntentId = action.payload.paymentIntent.id;
                state.clientSecretloading = 'succeeded';
            }
        },
        [getClientSecret.rejected]: (state, action) => {
            if (state.clientSecretloading === 'pending') {
                state.clientSecretError = action.error;
                state.clientSecretloading = 'failed';
            }
        },
    },
});

export const { setConfirmPaymentOnMobile, resetPayment } = paymentSlice.actions;
export const { reducer: paymentReducer } = paymentSlice;
