export const routes = [
    { path: '/:locale' },
    { path: '/:locale/onboarding-details' },
    { path: '/:locale/pricing' },
    { path: '/:locale/order' },
    { path: '/:locale/user-details' },
    { path: '/:locale/payment' },
    { path: '/:locale/order-pick-up' },
    { path: '/:locale/order-details/:trackingId' },
];
