import React from 'react';

// components
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Accordion from 'react-bootstrap/Accordion';

// assets
import logo from '../../assets/icons/logo.svg';
import instagram from '../../assets/icons/instagram.svg';
import facebook from '../../assets/icons/facebook.svg';
import youtube from '../../assets/icons/youtube.svg';

const Footer = () => {
    const renderCustomerServiceUrls = () => (
        <div className="d-flex flex-column">
            <a href="https://www.stoeckli.ch/chde/shops/ski/ski-service-at-home#faq" target="_blank" rel="noopener">
                <FormattedMessage id="footer.faq" defaultMessage="FAQ" />
            </a>
            <a href="https://www.stoeckli.ch/chde/agb-skiservice" target="_blank" rel="noopener">
                <FormattedMessage id="footer.termsAndCondition" defaultMessage="Terms and Condition" />
            </a>
            <a href="https://www.stoeckli.ch/chde/shop/beratung" target="_blank" rel="noopener">
                <FormattedMessage id="footer.salesAdvice" defaultMessage="Beratung per Telefon oder E-Mail" />
            </a>
        </div>
    );

    const renderAboutStockliUrls = () => (
        <div className="d-flex flex-column">
            <a href="https://www.stoeckli.ch/chde/shop/filialen-finden" target="_blank" rel="noopener">
                <FormattedMessage id="footer.storeLocator" defaultMessage="Store locator" />
            </a>
            <a href="https://www.stoeckli.ch/chde/news" target="_blank" rel="noopener">
                <FormattedMessage id="footer.news" defaultMessage="News" />
            </a>
            <a href="https://www.stoeckli.ch/chde/jobs" target="_blank" rel="noopener">
                <FormattedMessage id="footer.jobs" defaultMessage="Jobs" />
            </a>
        </div>
    );

    const renderContactUrls = () => (
        <div className="d-flex flex-column">
            <p>
                <FormattedMessage id="footer.stöckliSwissSportsAG" defaultMessage="Stöckli Swiss Sports AG" />
            </p>
            <p>
                <FormattedMessage id="footer.eistrasse" defaultMessage="Eistrasse 5a" />
            </p>
            <p>
                <FormattedMessage id="footer.malters" defaultMessage="CH-6102 Malters" />
            </p>
            <a href="tel:+41414926222">
                <FormattedMessage id="footer.tel" defaultMessage="Tel +41 41 492 62 22" />
            </a>
            <a href="https://www.stoeckli.ch/chde/kontakt" target="_blank" rel="noopener">
                <FormattedMessage id="footer.contactUs" defaultMessage="Contact us →" />
            </a>
        </div>
    );

    return (
        <>
            <div className="footer-section">
                <Container className="main-container">
                    <div className="footer-main">
                        <div className="img-area">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="img-area">
                            <h2>
                                <FormattedMessage id="footer.customerService" defaultMessage="CUSTOMER SERVICE" />
                            </h2>
                            {renderCustomerServiceUrls()}
                        </div>
                        <div className="img-area">
                            <h2>
                                <FormattedMessage id="footer.about" defaultMessage="ABOUT STÖCKLI" />
                            </h2>
                            {renderAboutStockliUrls()}
                        </div>
                        <div className="img-area">
                            <h2>
                                <FormattedMessage id="footer.contact" defaultMessage="CONTACT" />
                            </h2>
                            {renderContactUrls()}
                        </div>
                    </div>
                </Container>
            </div>

            <div className="mobile-footer">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <FormattedMessage id="footer.customerService" defaultMessage="CUSTOMER SERVICE" />
                        </Accordion.Header>
                        <Accordion.Body>{renderCustomerServiceUrls()}</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <FormattedMessage id="footer.about" defaultMessage="ABOUT STÖCKLI" />
                        </Accordion.Header>
                        <Accordion.Body>{renderAboutStockliUrls()}</Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <FormattedMessage id="footer.contact" defaultMessage="CONTACT" />
                        </Accordion.Header>
                        <Accordion.Body>{renderContactUrls()}</Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="footer-section-mini">
                <Container className="main-container">
                    <div className="footer-main-mini">
                        <p>
                            <a href="https://www.stoeckli.ch/chde/imprint" target="_blank" rel="noopener">
                                <FormattedMessage id="footer.imprint" defaultMessage="Imprint" />
                            </a>{' '}
                            |{' '}
                            <a
                                href="https://www.stoeckli.ch/chde/impressum-rechtliche-hinweise"
                                target="_blank"
                                rel="noopener"
                            >
                                <FormattedMessage
                                    id="footer.termsOfUseAndPrivacy"
                                    defaultMessage="Terms of Use & Privacy Policy"
                                />
                            </a>
                            {/* | {' '}<FormattedMessage id="footer.cookieSettings" defaultMessage="Cookie settings" />*/}
                        </p>
                        <div className="social-icons">
                            <a href="https://www.instagram.com/stoeckli_1935/" target="_blank" rel="noopener">
                                <img src={instagram} alt="instagram" />
                            </a>
                            <a href="https://www.youtube.com/user/StoeckliSki" target="_blank" rel="noopener">
                                <img src={youtube} alt="youtube" />
                            </a>
                            <a href="https://www.facebook.com/stoeckli1935" target="_blank" rel="noopener">
                                <img src={facebook} alt="facebook" />
                            </a>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Footer;
