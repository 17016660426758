import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPricings } from '../../../services/restApis';
import { LoadingState } from '../../../types';

export const getPricingOptionAction: any = createAsyncThunk('Pricing', async (param, { getState }) => {
    const state: any = getState();
    const response = await getPricings(state.order.baseCondition);
    return response.data;
});

export interface PricingOption {
    id: number;
    price: string;
    currency_type: string;
    recommended: boolean;
    translations: any;
    transport_cost: string;
    is_best_seller: boolean;
    transport_text: string;
}

export interface PricingState {
    options: PricingOption[];
    api: { loading: LoadingState; error: null };
}

const initialState: PricingState = {
    options: [],
    api: { loading: 'idle', error: null },
};

export const pricingSlice = createSlice({
    name: 'pricingSlice',
    initialState,
    reducers: {
        resetPricing: () => initialState,
    },
    extraReducers: {
        [getPricingOptionAction.pending]: state => {
            state.api.loading = 'pending';
            state.api.error = null;
        },
        [getPricingOptionAction.fulfilled]: (state, action) => {
            state.options = action.payload;
            state.api.loading = 'succeeded';
        },
        [getPricingOptionAction.rejected]: (state, action) => {
            state.api.error = action.error;
            state.api.loading = 'failed';
        },
    },
});
export const { resetPricing } = pricingSlice.actions;
export const { reducer: pricingReducer } = pricingSlice;
