import { backendXHR } from '../utils/xhr';

// types
import { OrderItem } from '../containers/Order/store/orderSlice';
import { UserDetail } from '../containers/OrderDetails/store/slice';

export interface GetSecretKeyPayload {
    amount: number;
    currency: string;
}

export interface PlaceOrderPayload {
    services: OrderItem[];
    user: UserDetail;
    order: { total: number; bag: number };
}

export interface UpdateOrderStatusPayload {
    stripe_payment_intent_id: string;
    stripe_response: string;
}

export interface GetStripePaymentDetailPayload {
    intent_id: string;
}

const getPricings = (baseCondition: string) => backendXHR.client.get(`ski-service/prices/?condition=${baseCondition}`);
const getOrder = () => backendXHR.client.get('ski-service/bags/');
const getSecretKey = (payload: GetSecretKeyPayload) => backendXHR.client.post('ski-service/payment-intent/', payload);
const placeOrder = (payload: PlaceOrderPayload) => backendXHR.client.post('ski-service/order-ski/', payload);
const updateOrderStatus = (payload: UpdateOrderStatusPayload, trackingId: string) =>
    backendXHR.client.patch(`ski-service/order/${trackingId}/`, payload);
const getOrderStatus = (id: string) => backendXHR.client.get(`ski-service/order/tracking/?tracking_id=${id}`);
const getStripePaymentDetail = (payload: GetStripePaymentDetailPayload) =>
    backendXHR.client.post('ski-service/card-detail/', payload);

export { getPricings, getOrder, getSecretKey, placeOrder, updateOrderStatus, getOrderStatus, getStripePaymentDetail };
