import * as React from 'react';
import { lazyLoad } from '../../utils/loadable';

import LoadingIndicator from '../../components/LoadingIndicator/indes';

export const UserDetails = lazyLoad(
    () => import('./index'),
    module => module.default,
    {
        fallback: <LoadingIndicator />,
    }
);
