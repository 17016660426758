import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { matchRoutes } from 'react-router-dom';

// components
import ErrorBoundary from './components/ErrorBoundary';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from 'react-intl';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import * as Sentry from '@sentry/react';

// store
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import { intlReducer } from 'react-intl-redux';
import { pricingReducer } from './containers/Pricing/store/slice';
import { skiBagsReducer } from './containers/Order/store/slice';
import { orderReducer } from './containers/Order/store/orderSlice';
import { userDetailsReducer } from './containers/UserDetails/store/slice';
import { paymentReducer } from './containers/Payment/store/slice';
import { orderStatusReducer } from './containers/OrderDetails/store/slice';

// styles
import './styles/custom_bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/index.scss';

// assets
import messages_de from './translations/de.json';

// constants
import config, { ENVIRONMENT } from '../src/constants/config';

const messages: any = {
    de: messages_de,
    // en: messages_en,
};

const routesMatch: any = matchRoutes(config.routes, window.location);

const locale = routesMatch && routesMatch.length ? routesMatch[0].params.locale : 'de';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const initialState = {
    intl: {
        locale,
        messages: {},
    },
};

const orderPersistConfig = {
    key: 'orderSlice',
    storage: sessionStorage,
};

const userDetailsPersistConfig = {
    key: 'userDetails',
    storage: sessionStorage,
};

export const store = configureStore({
    reducer: combineReducers({
        router: routerReducer,
        intl: intlReducer,
        pricing: pricingReducer,
        order: persistReducer(orderPersistConfig, orderReducer),
        skiBags: skiBagsReducer,
        userDetails: persistReducer(userDetailsPersistConfig, userDetailsReducer),
        payment: paymentReducer,
        orderStatus: orderStatusReducer,
    }),
    preloadedState: initialState,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }).concat(routerMiddleware).concat(thunk),
});

export const history = createReduxHistory(store);

const persistor = persistStore(store);

if (ENVIRONMENT !== 'local') {
    Sentry.init({
        dsn: 'https://22906dd8afa155c4402fb3a5db44dba2@o155039.ingest.sentry.io/4505799967834112',
    });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <IntlProvider locale={navigator.language} defaultLocale={navigator.language} messages={messages[locale]}>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </IntlProvider>
            </Router>
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
