import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { placeOrder, PlaceOrderPayload, updateOrderStatus, UpdateOrderStatusPayload } from '../../../services/restApis';
import { SkiBag } from './slice';

// types
import { PricingOption } from '../../Pricing/store/slice';
import { LoadingState } from '../../../types';
import { PaymentIntentResult } from '@stripe/stripe-js';

export const placeOrderAction: any = createAsyncThunk('placeOrder', async (param: PlaceOrderPayload) => {
    const response = await placeOrder(param);
    return response.data;
});

export const updateOrderStatusAction: any = createAsyncThunk(
    'updateOrderStatus',
    async (param: UpdateOrderStatusPayload, { getState }) => {
        const state: any = getState();
        const response = await updateOrderStatus(param, state.order.trackingId);
        return response.data;
    }
);

export interface OrderItem {
    price_option: number;
    base_condition: string;
    visible_damage: string;
    quantity: number;
}

type BaseCondition = 'NEW' | 'SCRATCHES' | 'BAD' | '';
type VisibleDamage = 'NO_DAMAGE' | 'BROKEN_SIDEWALLS' | 'BROKEN_EDGES' | 'SMALL_EDGES' | '';

export interface PricingOptionSelection extends PricingOption {
    quantity: number;
    base_condition: string;
    visible_damage: string;
}

export interface Order {
    order: OrderItem[];
    selectedOptions: PricingOptionSelection[];
    selectedSkiBag: null | SkiBag;
    currentSelectedOption: PricingOptionSelection | null;
    subTotal: number;
    isOrderPaid: boolean;
    trackingId: number | null;
    updateOrderStatusLoading: LoadingState;
    updateOrderStatusErrorr: null;
    baseCondition: BaseCondition;
    visibleDamage: VisibleDamage;
    isPaying: LoadingState;
    stripePaymentIntentResult: PaymentIntentResult | null;
    api: { loading: LoadingState; error: any };
}

const initialState: Order = {
    order: [],
    selectedOptions: [],
    selectedSkiBag: null,
    currentSelectedOption: null,
    subTotal: 0,
    isOrderPaid: false,
    trackingId: null,
    updateOrderStatusLoading: 'idle',
    updateOrderStatusErrorr: null,
    baseCondition: '',
    visibleDamage: '',
    isPaying: 'idle',
    stripePaymentIntentResult: null,
    api: { loading: 'idle', error: null },
};

export const orderSlice = createSlice({
    name: 'orderSlice',
    initialState,
    reducers: {
        setOrder: (state, action) => {
            state.order = action.payload;
        },
        setSelectedBag: (state, action) => {
            state.selectedSkiBag = action.payload;
        },
        setSelectedOption: (state, action) => {
            action.payload['base_condition'] = state.baseCondition;
            action.payload['visible_damage'] = state.visibleDamage;
            if (state.selectedOptions.length < 3) {
                const index = state.selectedOptions.findIndex(
                    option =>
                        option.id === action.payload.id &&
                        option?.base_condition === action?.payload?.base_condition &&
                        option?.visible_damage === action?.payload?.visible_damage
                );
                if (index !== -1) {
                    if (state.selectedOptions[index].quantity < 3) {
                        state.selectedOptions[index].quantity++;
                    }
                } else {
                    state.selectedOptions.push({ ...action.payload, quantity: 1 });
                }
                state.currentSelectedOption = action.payload;
            }
        },
        removeSelectedOption: (state, action) => {
            const index = state.selectedOptions.findIndex(
                option =>
                    option.id === action.payload.id &&
                    option?.base_condition === action?.payload?.base_condition &&
                    option?.visible_damage === action?.payload?.visible_damage
            );
            const selectedOptionsClone = [...state.selectedOptions];
            selectedOptionsClone.splice(index, 1);
            state.selectedOptions = selectedOptionsClone;

            if (state.currentSelectedOption?.id === action.payload.id) {
                state.currentSelectedOption = null;
            }
        },
        removeSelectedSkiBag: state => {
            state.selectedSkiBag = null;
        },
        setSubTotal: (state, action) => {
            state.subTotal = action.payload;
        },
        setBaseCondition: (state, action) => {
            state.baseCondition = action.payload;
        },
        setVisibleDamage: (state, action) => {
            state.visibleDamage = action.payload;
        },
        setIsPaying: (state, action) => {
            state.isPaying = action.payload;
        },
        setStripePaymentIntentResult: (state, action) => {
            state.stripePaymentIntentResult = action.payload;
        },
        resetTrackingId: state => {
            state.trackingId = null;
        },
        updateSelectedOptions: (state, action) => {
            const selectedOptionIndex = state.selectedOptions.findIndex(
                (item: PricingOptionSelection) =>
                    item.id === state.currentSelectedOption?.id &&
                    item.base_condition === state.currentSelectedOption.base_condition &&
                    item.visible_damage === state.currentSelectedOption.visible_damage
            );
            state.selectedOptions[selectedOptionIndex] = { ...action.payload, quantity: 1 };
            state.currentSelectedOption = action.payload;
        },
        resetSelectedOptions: state => {
            state.baseCondition = '';
            state.visibleDamage = '';
            state.currentSelectedOption = null;
        },
        resetOrder: () => initialState,
    },
    extraReducers: {
        [placeOrderAction.pending]: state => {
            state.api.loading = 'pending';
            state.api.error = null;
        },
        [placeOrderAction.fulfilled]: (state, action) => {
            state.trackingId = action.payload.order.tracking_id;
            state.api.loading = 'succeeded';
        },
        [placeOrderAction.rejected]: (state, action) => {
            state.api.error = action.error;
            state.api.loading = 'failed';
        },
        [updateOrderStatusAction.pending]: state => {
            state.updateOrderStatusLoading = 'pending';
            state.updateOrderStatusErrorr = null;
        },
        [updateOrderStatusAction.fulfilled]: state => {
            state.updateOrderStatusLoading = 'succeeded';
            state.isPaying = 'succeeded';
            state.isOrderPaid = true;
            state.api.loading = 'succeeded';
        },
        [updateOrderStatusAction.rejected]: (state, action) => {
            state.updateOrderStatusErrorr = action.error;
            state.updateOrderStatusLoading = 'failed';
            state.isPaying = 'failed';
        },
    },
});

export const {
    setOrder,
    setSelectedBag,
    setSelectedOption,
    removeSelectedOption,
    removeSelectedSkiBag,
    setSubTotal,
    setBaseCondition,
    setVisibleDamage,
    setIsPaying,
    resetTrackingId,
    resetOrder,
    resetSelectedOptions,
    updateSelectedOptions,
    setStripePaymentIntentResult,
} = orderSlice.actions;
export const { reducer: orderReducer } = orderSlice;
