import { To, useNavigate, useParams } from 'react-router';

const useNavigateWithLocale = () => {
    const navigate = useNavigate();
    const param = useParams();

    return (to: To) => navigate(`/${param?.locale}${to}`);
};

export default useNavigateWithLocale;
