import React, { useEffect } from 'react';

// components
import { Onboarding } from './containers/Onboarding/Loadable';
import BaseLayout from './containers/BaseLayout';
import { Pricing } from './containers/Pricing/Loadable';
import { OnboardingStep2 } from './containers/OnboardingStep2/Loadable';
import { Order } from './containers/Order/Loadable';
import { UserDetails } from './containers/UserDetails/Loadable';
import { Payment } from './containers/Payment/Loadable';
import { SwissPost } from './containers/SwissPost/Loadable';
import { OrderDetails } from './containers/OrderDetails/Loadable';
import NotFound from './components/404NotFound';
import RouteGuard from './components/RouteGuard';

// hooks
import { Route, Routes, useLocation } from 'react-router-dom';

// styles
import './styles/App.scss';

const App = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <RouteGuard>
                        <BaseLayout />
                    </RouteGuard>
                }
            >
                <Route
                    index
                    path="/:locale"
                    element={
                        <RouteGuard>
                            <Onboarding />
                        </RouteGuard>
                    }
                />
                {/*<Route*/}
                {/*    path="/:locale/onboarding-details"*/}
                {/*    element={*/}
                {/*        <RouteGuard>*/}
                {/*            <OnboardingStep2 />*/}
                {/*        </RouteGuard>*/}
                {/*    }*/}
                {/*/>*/}
                <Route
                    path="/:locale/pricing"
                    element={
                        <RouteGuard>
                            <Pricing />
                        </RouteGuard>
                    }
                />
                <Route
                    path="/:locale/order"
                    element={
                        <RouteGuard>
                            <Order />
                        </RouteGuard>
                    }
                />
                <Route
                    path="/:locale/user-details"
                    element={
                        <RouteGuard>
                            <UserDetails />
                        </RouteGuard>
                    }
                />
                <Route
                    path="/:locale/payment"
                    element={
                        <RouteGuard>
                            <Payment />
                        </RouteGuard>
                    }
                />
                <Route
                    path="/:locale/order-pick-up"
                    element={
                        <RouteGuard>
                            <SwissPost />
                        </RouteGuard>
                    }
                />
                <Route
                    path="/:locale/order-details/:trackingId"
                    element={
                        <RouteGuard>
                            <OrderDetails />
                        </RouteGuard>
                    }
                />
                <Route path="/:locale/404" element={<NotFound />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default App;
