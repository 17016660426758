import * as React from 'react';

import { Spinner } from 'react-bootstrap';

const LoadingIndicator = () => (
    <div className="d-flex align-items-center justify-content-center w-100 vh-100">
        <Spinner />
    </div>
);

export default LoadingIndicator;
