import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOrder } from '../../../services/restApis';
import { LoadingState } from '../../../types';

export const getSkiBagsAction: any = createAsyncThunk('getSkiBags', async param => {
    const response = await getOrder();
    return response.data;
});

export interface SkiBag {
    id: number;
    translations: any;
    image: string;
    price: string;
    thumbnail_url: string;
}

export interface OrderState {
    skiBagsList: SkiBag[];
    api: { loading: LoadingState; error: null };
}

const initialState: OrderState = {
    skiBagsList: [],
    api: { loading: 'idle', error: null },
};

const skiBagsSlice = createSlice({
    name: 'skiBagsSlice',
    initialState,
    reducers: {},
    extraReducers: {
        [getSkiBagsAction.pending]: state => {
            if (state.api.loading === 'idle') {
                state.api.loading = 'pending';
                state.api.error = null;
            }
        },
        [getSkiBagsAction.fulfilled]: (state, action) => {
            if (state.api.loading === 'pending') {
                state.skiBagsList = action.payload;
                state.api.loading = 'succeeded';
            }
        },
        [getSkiBagsAction.rejected]: (state, action) => {
            if (state.api.loading === 'pending') {
                state.api.error = action.error;
                state.api.loading = 'failed';
            }
        },
    },
});

export const { reducer: skiBagsReducer } = skiBagsSlice;
