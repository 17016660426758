import React from 'react';
import { store } from '../..';

// components
import { matchRoutes, Navigate, useLocation, useParams } from 'react-router-dom';

// constants
import config from '../../constants/config';

interface RouteGuardProps {
    children: React.ReactNode;
}

const RouteGuard = ({ children }: RouteGuardProps) => {
    const param: any = useParams();
    const { pathname } = useLocation();

    const routesMatch: any = matchRoutes(config.routes, window.location);
    const path: string = routesMatch && routesMatch.length ? routesMatch[0].route.path : '';

    // using getState() here to avoid re-rendering that comes with useSelector
    const isBaseConditionSelected: boolean = !!store.getState().order.baseCondition;
    const isPricingSelected: boolean = store.getState().order.selectedOptions.length > 0;
    const isOrderSelected: boolean = store.getState().order.order.length > 0;
    const isUserDetailsFilled: boolean = !!store.getState().userDetails.user.firstName;
    const isOrderPaid: boolean = store.getState().order.isOrderPaid;

    const locale = param.locale ? param.locale : 'de';

    if (pathname === '/') {
        return <Navigate to="/de" />;
    }

    if (!config.availableLocales.includes(locale)) {
        return <Navigate to="/de/404" />;
    }

    if (path === config.routes[1].path && !isBaseConditionSelected) {
        return <Navigate to={`/${locale}`} />;
    }

    if (path === config.routes[3].path && !isPricingSelected) {
        return <Navigate to={`/${locale}/pricing`} />;
    }

    if (path === config.routes[4].path && !isOrderSelected) {
        return <Navigate to={`/${locale}/pricing`} />;
    }

    if (path === config.routes[5].path && !isUserDetailsFilled) {
        return <Navigate to={`/${locale}/user-details`} />;
    }

    if (path === config.routes[6].path && !isOrderPaid) {
        return <Navigate to={`/${locale}/payment`} />;
    }

    return children;
};

export default RouteGuard;
