import React from 'react';

// styles
import './Header.scss';

// hooks
import useNavigateWithLocale from '../../hooks/useNavigateWithLocale';

// assets
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';

const Header = () => {
    const navigate = useNavigateWithLocale();

    return (
        <div className="w-100 p-4 d-flex justify-content-center align-items-center">
            <Logo
                onClick={() => window.open('https://www.stoeckli.ch/chde/', '_blank', 'noreferrer')}
                className={'cursor-pointer'}
            />
        </div>
    );
};

export default Header;
